import debounce from '../core/debounce';

// form
const form = {
    baseError: {
        message: ''
    },
    params: {
        currentForm : '',
        listError : [],
        classLiError : 'widget__error',
        classUlErrors : 'widget__errors',
        classError : 'widget__input--error',
        classValid : 'widget__input--valid',
        classRadio: '.widget--radio',
        classDisabled : 'button--disabled',
        classButtonValid : 'button--valid',
        classPasswordToggle : 'widget--password__toggle',
        dataErrorMsg : 'data-error-messages',
        dataUrl : '',
        ongoing: {}
    },
    initForm: function(form) {
        this.params.currentForm = document.querySelector(form);
        const inputs = this.params.currentForm.querySelectorAll('input');
        const radioWidgets = this.params.currentForm.querySelectorAll(this.params.classRadio);
        const passwordToggle = this.params.currentForm.querySelectorAll('.' + this.params.classPasswordToggle);
        const error = this.baseError;
        const self = this;

        this.params.currentForm.addEventListener('submit', function(e) {
            if (!this.checkValidity()) {
                e.preventDefault();

                for (let i = 0; i < inputs.length; ++i) {
                    if (self.checkRequired(inputs[i])) {
                        self.checkPattern(inputs[i]);
                    }
                }

                for (let i = 0; i < radioWidgets.length; i++) {
                    self.params.listError[radioWidgets[i]] = error;
                    self.checkRadio(radioWidgets[i]);
                }
            }
        });

        for (let i = 0, l = passwordToggle.length; i < l; i++) {
            passwordToggle[i].addEventListener('click', function(e){
                self.togglePassword(e);
            });
        }

        for (let i = 0; i < inputs.length; ++i) {
            self.params.listError[inputs[i]] = error;
            let delay = 5000;

            if (inputs[i].getAttribute('type') === 'password') {
                delay = 1000;
            }

            inputs[i].addEventListener('input',debounce(function() {
                if (self.checkRequired(this)) {
                    self.checkPattern(this);
                } else {
                    self.checkForm();
                }
            }, delay));

            inputs[i].addEventListener('change', function() {
                if (self.checkRequired(this)) {
                    self.checkPattern(this);
                } else {
                    self.checkForm();
                }
            });
        }

        this.checkForm();
    },
    checkRequired: function(input) {
        const required = input.getAttribute('required');

        if (input.validity.valid === null || !required) {
            return;
        }

        let isValid = input.value !== '';
        if (input.type === 'checkbox') {
            isValid = input.validity.valid;
        }

        if (!isValid) {
            const json = input.getAttribute(this.params.dataErrorMsg);
            const error = JSON.parse(json);

            input.classList.add(this.params.classError);
            input.classList.remove(this.params.classValid);

            if (error) {
                this.params.listError[input].message = error[0].required;
            }
            this.listErrorMessages(input);
            return false;
        }

        input.classList.remove(this.params.classError);
        this.params.listError[input].message = '';
        input.classList.add(this.params.classValid);
        return true;
    },
    checkPattern: function(input) {
        if (input.validity.patternMismatch) {
            const json = input.getAttribute(this.params.dataErrorMsg);
            const errorPattern = JSON.parse(json);

            input.classList.add(this.params.classError);
            input.classList.remove(this.params.classValid);
            if (errorPattern) {
                this.params.listError[input].message = errorPattern[0].pattern;
            }

        } else {
            input.classList.remove(this.params.classError);
            input.classList.add(this.params.classValid);
            this.params.listError[input].message = '';

            if (input.getAttribute('maxlength')) {
                this.checkMaxlength(input);
            }
            if (input.getAttribute('data-api-validation')) {
                this.checkApi(input);
            }
        }
        this.listErrorMessages(input);
        this.checkForm();
    },
    checkMaxlength: function(input) {
        const inputMaxlength = input.getAttribute('maxlength');
        const inputValue = input.value.length;
        const json = input.getAttribute(this.params.dataErrorMsg);
        const error = JSON.parse(json);

        if (inputValue > inputMaxlength) {
            const json = input.getAttribute(this.params.dataErrorMsg);
            const dataError = JSON.parse(json);

            input.classList.add(this.params.classError);
            input.classList.remove(this.params.classValid);

            if (dataError) {
                this.params.listError[input].message = error[0].maxlength;
            }

        } else {
            const inputValue = input.value;

            input.classList.remove(this.params.classError);
            input.classList.add(this.params.classValid);
            this.params.listError[input].message = '';
        }

        this.listErrorMessages(input)

    },
    checkRadio: function(widget) {
        const inputs = widget.querySelectorAll('input');
        let inputNames = [];
        let valid = false;

        for (let i = 0, l = inputs.length; i < l; i++) {
            let name = inputs[i].getAttribute('name');

            if (!inputNames.includes(name)) {
                inputNames.push(name);
            }
        }

        inputNames.forEach(function(name) {
            const elements = widget.querySelectorAll('[name="'+name+'"]');
            let required = false;

            for (let i = 0, l = elements.length; i < l; i++) {
                if (elements[i].getAttribute('required')) {
                    required = true;
                    break;
                }
            }

            if (required) {
                for (let i = 0, l = elements.length; i < l; i++) {
                    if (elements[i].checked) {
                        valid = true;
                        break;
                    }
                }
            }
        });

        if (!valid) {
            const wrapper = widget.querySelector('[' + this.params.dataErrorMsg + ']');
            const json = wrapper.getAttribute(this.params.dataErrorMsg);
            const error = JSON.parse(json);

            if (error) {
                this.params.listError[widget].message = error[0].required;
            }
            this.listErrorMessages(wrapper);

        } else {
            this.params.listError[widget].message = '';
            this.checkForm();
        }
    },
    listErrorMessages: function(input) {
        let list = input.closest('.widget').querySelector(`.${this.params.classUlErrors}`);
        let listItem = input.closest('.widget').querySelector(`.${this.params.classLiError}`);

        if (!list && !listItem) {
            list = document.createElement("ul");
            list.classList.add(this.params.classUlErrors);
            input.parentNode.appendChild(list);

            listItem = document.createElement("li");
            listItem.classList.add(this.params.classLiError);
            list.appendChild(listItem);
        } else {
            listItem.innerHTML = '';
        }

        for (let prop in this.params.listError[input]) {
            if (this.params.listError[input][prop] !== '') {
                listItem.innerHTML = this.params.listError[input][prop];
            }
        }
    },
    checkApi: function(input) {
        const self = this;
        const url = input.getAttribute('data-api-validation');
        const field = input.getAttribute('data-api-validation-field');
        const listError = [];
        const dataToSend = {};

        // Init value
        dataToSend[field] = input.value;

        if (url && !this.params.ongoing[input]) {
            this.params.ongoing[input] = true;
            fetch(url, {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(dataToSend)
            }).then(function(response) {
                return response.json().then((val) => {
                    self.params.ongoing[input] = false;
                    if (response.ok) {
                        input.classList.add(self.params.classValid);
                        self.params.listError[input].message = '';
                        self.listErrorMessages(input);
                        return;
                    }

                    if (response.status === 400) {
                        for (let i = 0; i < val.violations.length; ++i) {
                            listError.push(val.violations[i].message);
                        }
                        self.params.listError[input].message = listError.join(' ');
                        input.classList.remove(self.params.classValid);
                        input.classList.add(self.params.classError);
                        self.listErrorMessages(input);
                        self.checkForm();
                        return;
                    }

                    throw new Error('Cannot handle request.');
                });
            }).catch(function() {
                self.params.ongoing[input] = false;
                self.params.listError[input].message = '';
                input.classList.add(self.params.classValid);
            });
        }
    },
    checkForm: function() {
        const inputs = this.params.currentForm.querySelectorAll('input:required');
        const buttonValid = this.params.currentForm.querySelector('.' + this.params.classButtonValid);
        let notValid = false;

        if (!this.params.currentForm.checkValidity()) {
            notValid = true;
        } else {
            for (let i = 0; i < inputs.length; ++i) {
                if (inputs[i].getAttribute('type') == 'radio') {
                    if (inputs[i].value == null) {
                        notValid = true;
                    }

                    continue;
                }

                if (inputs[i].classList.contains(this.params.classError)) {
                    notValid = true;
                    continue;
                }
            }
        }

        if (notValid){
            buttonValid.classList.add(this.params.classDisabled);
        } else {
            buttonValid.classList.remove(this.params.classDisabled);
        }
    },
    togglePassword: function(event) {
        const target = event.target;
        const self = this;
        const input = document.querySelectorAll(target.dataset.input);
        const textToggleShow = target.getAttribute('data-show');
        const textToggleHide = target.getAttribute('data-hide');

        for (let i = 0, l = input.length; i < l; i++) {
            const type = input[i].getAttribute('type');

            switch(type) {
                case 'text':
                    input[i].setAttribute('type', 'password');
                    target.classList.remove(self.params.classPasswordToggle + '--close');
                    target.textContent = textToggleShow;
                    break;
                case 'password':
                    input[i].setAttribute('type', 'text');
                    target.classList.add(self.params.classPasswordToggle + '--close');
                    target.textContent = textToggleHide;
                    break;
            };
        }
    }
}

window.addEventListener('load', function() {
    form.initForm('.form');
});

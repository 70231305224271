function collapse () {
    const coll = document.querySelectorAll('[data-collapse]');

    for (let i = 0; i < coll.length; i++) {
        const currentElement = coll[i];
        const targets = document.getElementsByClassName(currentElement.dataset.target);

        currentElement.addEventListener("click", function() {
            this.classList.toggle("active");

            for (let y = 0; y < targets.length; y++) {
                const target = targets[y];
                target.classList.add('collapse');

                if (target.style.maxHeight) {
                    target.style.maxHeight = null;
                } else {
                    target.style.maxHeight = target.scrollHeight + "px";
                }
            }
        });
    }
}

module.exports = collapse;

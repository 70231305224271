// Make an element toggle the visibility of another element on click
function toggle(selector) {
    const nodes = document.querySelectorAll(selector);

    for (let i = 0, l = nodes.length; i < l; i++) {
        const node = nodes[i];
        const target = document.querySelectorAll(node.dataset.target);

        if (target[0] != null) {
            node.addEventListener('click', function() {
                const isVisible = target[0].classList.contains("visible");

                if (isVisible === true) {
                    node.classList.remove("active");
                    target[0].classList.remove("visible");
                } else {
                    node.classList.add("active");
                    target[0].classList.add("visible");
                }
            });
        }
    }

    // When the user clicks anywhere outside of the element, close it
    window.addEventListener('click', (event) => {

        const nodes = document.querySelectorAll(selector);
        let nodeArray = Array.from(nodes);

        nodeArray.forEach( (node) => {

            const targetToggle = document.querySelectorAll(node.dataset.target);

            if (node.contains(event.target) === false) {
                node.classList.remove("active");
                targetToggle[0].classList.remove("visible");
            }
        });
    });
}

module.exports = toggle;
